import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { signInProtectionGuard } from './guards/sign-in-protection.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./views/main/main.module').then((m) => m.MainModule),
  },
  {
    path: 'sign-in',
    loadChildren: () =>
      import('./views/sign-in/sign-in.module').then((m) => m.SignInModule),
    canActivate: [signInProtectionGuard],
  },
  {
    path: 'monthly-report',
    loadChildren: () =>
      import('./views/monthly-report/monthly-reporting.module').then(
        (m) => m.MonthlyReportModule
      ),
  },
  {
    path: 'auth/callback',
    redirectTo: 'dashboard',
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
