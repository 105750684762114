import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NativeBridgeService } from './core/services/native-bridge.service';
import { UtilService } from './core/services/util.service';
import { AppService } from './core';
import { PLATFORM } from './constants';
import { MetaService } from './core/services/meta.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'jio-loyalty-website';
  constructor(
    private nativeBridgeService: NativeBridgeService,
    private utilService: UtilService,
    private appService: AppService,
    private metaService: MetaService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.utilService.getParameterByName('platform') == 'mobile') {
      this.appService.setPlatform(PLATFORM.NATIVE_APP);
      this.getLocationForNativeApp();
    }
    this.metaService.getMetaTags().subscribe(() => {
      this.updateMetaTags(this.router.url);
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.updateMetaTags(this.router.url);
        }
      });
    });
  }

  sendMessageToNative() {
    const message = { key: 'value' };
    this.nativeBridgeService.callNativeFunction(message);
  }
  updateMetaTags(url: string) {
    const route = url.split('/')[1];
    this.metaService.updateMetaTags(route);
  }
  getLocationForNativeApp() {
    this.appService.setCity(this.utilService.getParameterByName('city') ?? '');
    this.appService.setPincode(
      this.utilService.getParameterByName('pincode') ?? ''
    );
  }
}
