import { DOCUMENT } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PLATFORM } from 'src/app/constants';
import { AppService } from 'src/app/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { NativeBridgeService } from 'src/app/core/services/native-bridge.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { DashboardService } from 'src/app/views/main/dashboard/dashboard.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  points = 0;
  userName = '';
  isHomePage = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private authService: AuthService,
    private profileService: ProfileService,
    private dashboardService: DashboardService,
    private nativeBridgeService: NativeBridgeService,
    private appService: AppService
  ) {}

  scrollDownThreshold = 80;
  scrollUpThreshold = 40;
  isLoggedIn = false;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  isNativeApp = false;

  commonResourcePath = 'COMMON_TEXT.';
  isHamBurgerClicked = false;
  isScrolled = false;
  showDashboard = false;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (this.authService.getIsAuthenticated()) {
      if (window.scrollY > this.scrollDownThreshold && !this.isScrolled) {
        this.isScrolled = true;
        // changeLogoAndBalance();
      } else if (window.scrollY <= this.scrollUpThreshold && this.isScrolled) {
        this.isScrolled = false;
        // resetLogoAndBalance();
      }
    }
  }

  ngOnInit(): void {
    forkJoin([
      this.dashboardService.getCarouselPartners(),
      this.dashboardService.getTopPartners(),
    ]).subscribe(([res1, res2]) => {
      if (res1.data.length || res2.data.length) {
        this.showDashboard = true;
      } else {
        this.showDashboard = false;
      }
    });

    this.authService.isLoggedIn$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isUserLoggedIn) => {
        this.isLoggedIn = Boolean(isUserLoggedIn);
        if (this.isLoggedIn) {
          this.authService.validateSession().subscribe((isValid) => {
            if (isValid) {
              this.getUserInfo();
            } else {
              this.isLoggedIn = false;
              this.router
                .navigate(['dashboard'], {
                  queryParams: { sessionExpired: true },
                })
                .catch(() => {});
            }
          });
        }
      });
    if (this.authService.getIsAuthenticated()) {
      this.authService.validateSession().subscribe((isValid) => {
        if (isValid) {
          this.isLoggedIn = true;
          this.getUserInfo();
        } else {
          this.isLoggedIn = false;
          this.router
            .navigate(['dashboard'], {
              queryParams: { sessionExpired: true },
            })
            .catch(() => {});
        }
      });
    }
    this.checkHomepage();
    if (this.appService.getPlatform() == PLATFORM.NATIVE_APP) {
      this.isNativeApp = true;
    }
  }
  getUserInfo() {
    this.profileService.getPointsSummary().subscribe((res) => {
      this.points = res.availableAmountForDisplay;
    });

    this.profileService.getProfileInfo().subscribe((res) => {
      if (!res.firstName) {
        this.userName = 'R-One Member';
      }
      this.userName = res.firstName;
      if (this.userName.includes(' ')) {
        const array = this.userName.split(' ');
        this.userName = array[0][0] + array[array.length - 1][0];
      } else {
        if (this.userName[1]) {
          this.userName = this.userName[0] + this.userName[1];
        } else {
          this.userName = this.userName[0];
        }
      }
    });
  }

  checkHomepage() {
    this.router.events.subscribe(() => {
      if (this.router.url === '/') {
        this.isHomePage = true;
      } else {
        this.isHomePage = false;
      }
    });
  }

  openStoreLocator() {
    window.open(environment.storeLocaterURL);
    this.nativeBridgeService.launchBrowser(environment.storeLocaterURL);
  }

  handleHamBurger() {
    this.isHamBurgerClicked = !this.isHamBurgerClicked;
    if (this.isHamBurgerClicked) {
      this.renderer.addClass(this.document.body, 'no-scroll');
    } else {
      this.renderer.removeClass(this.document.body, 'no-scroll');
    }
  }

  signIn() {
    // Navigate silently to /view
    const clientId = environment.clientIdForRetailIDAM;
    const retailIDAMURL = environment.retailIDAMURL;
    const returnUIURL = `${window.location.origin}/auth/callback`;
    if (!clientId || !retailIDAMURL) {
      this.router.navigate(['/sign-in']).catch(() => {});
    } else
      document.location.href = `${retailIDAMURL}/sign-up?client_id=${clientId}&return_ui_url=${returnUIURL}`;
  }

  handleLogo() {
    this.router.navigate(['dashboard']).catch(() => {});
  }

  handleProfile() {
    this.router.navigate(['profile']).catch(() => {});
  }

  handleNavigation(redirectTo: string) {
    this.isHamBurgerClicked = false;
    this.renderer.removeClass(this.document.body, 'no-scroll');
    switch (redirectTo) {
      case 'support':
        this.router.navigate(['/support']).catch(() => {});
        break;
      case 'partners':
        this.router.navigate(['/partners']).catch(() => {});
        break;
      case 'coupons':
        this.router.navigate(['/coupons']).catch(() => {});
        break;
      case 'transactions':
        this.router.navigate(['/transactions']).catch(() => {});
        break;
      case 'dashboard':
        this.router.navigate(['/dashboard']).catch(() => {});
        break;
      case 'home':
        this.router.navigate(['/home']).catch(() => {});
        break;
      case 'profile':
        this.handleProfile();
        break;
      case 'store':
        this.openStoreLocator();
        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this.isHamBurgerClicked = false;
    this.renderer.removeClass(this.document.body, 'no-scroll');
  }
}
