import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { APPEND_TOKEN } from 'src/app/constants';
import { environment } from 'src/environments/environment';
import { TitleService } from './title.service';
export interface MetaTags {
  data: {
    id: number;
    attributes: {
      route: string;
      title: string;
      description: string;
      canUrl: string;
      keywords: string;
    };
  }[];
}

export interface MetaTagsData {
  route: string;
  title: string;
  description: string;
  canUrl: string;
  keywords: string;
}
@Injectable({
  providedIn: 'root',
})
export class MetaService {
  metaTags: MetaTagsData[] = [];

  constructor(
    @Inject(DOCUMENT) private dom: Document,
    private httpClient: HttpClient,
    private metaService: Meta,
    private titleServie: TitleService
  ) {}

  getMeta(): Observable<MetaTags> {
    return this.httpClient.get<MetaTags>(`${environment.cmsHost}/api/metas`, {
      context: new HttpContext().set(APPEND_TOKEN, false),
    });
  }

  getMetaTags(): Observable<MetaTags | null> {
    return this.getMeta().pipe(
      tap((response) => {
        this.metaTags = response.data.map((tag) => ({
          route: tag.attributes.route,
          title: tag.attributes.title,
          description: tag.attributes.description,
          canUrl: tag.attributes.canUrl,
          keywords: tag.attributes.keywords,
        }));
      }),
      catchError((error) => {
        console.error('Error loading meta tags', error);
        return of(null);
      })
    );
  }

  updateMetaTags(route: string) {
    const splittedUrl = (url: string): string =>
      url.substring(url.lastIndexOf('/') + 1);
    const metaTag = this.metaTags.find(
      (meta) => splittedUrl(meta.route) === route
    );

    if (metaTag) {
      this.metaService.updateTag({ name: 'title', content: metaTag.title });
      this.metaService.updateTag({ name: 'url', content: metaTag.route });
      this.metaService.updateTag({
        name: 'description',
        content: metaTag.description,
      });
      this.metaService.updateTag({
        name: 'keywords',
        content: metaTag.keywords,
      });
      this.updateCanonicalUrl(metaTag?.canUrl);
      this.titleServie.setTitle(metaTag?.title);
    } else {
      this.metaService.removeTag('name="title"');
      this.metaService.removeTag('name="url"');
      this.metaService.removeTag('name="description"');
      this.metaService.removeTag('name="keywords"');
    }
  }
  updateCanonicalUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    let element = this.dom.querySelector<HTMLLinkElement>(
      `link[rel='canonical']`
    );
    if (url) {
      if (!element) {
        element = this.dom.createElement('link');
        element.setAttribute('rel', 'canonical');
        head.appendChild(element);
      }

      element.setAttribute('href', url);
    } else {
      if (element) {
        head.removeChild(element);
      }
    }
  }
}
