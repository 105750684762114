import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent implements OnInit {
  showUnknownError = false;
  showProfileError = false;
  showSessionTimeoutError = false;
  isUserBlocked = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {}

  @Input() heading = '';
  @Input() description = '';
  @Input() contactSupportLink = '';

  @Output() closePopup = new EventEmitter<void>();

  ngOnInit(): void {}

  handlePopup() {
    if (
      this.showUnknownError ||
      this.showProfileError ||
      this.isUserBlocked ||
      this.showSessionTimeoutError
    ) {
      window.location.href = `${window.location.origin}/dashboard`;
      this.renderer.addClass(this.document.body, 'no-scroll');
    } else {
      this.renderer.removeClass(this.document.body, 'no-scroll');
    }
    this.resetFlags();
  }

  handleClickInsidePopup($event: Event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    $event.stopPropagation();
  }

  onCloseClick(): void {
    this.closePopup.emit();
  }

  contactSupport(): void {
    window.location.href = `${window.location.origin}/support?to=help`;
  }

  checkFAQ(): void {
    window.location.href = `${window.location.origin}/support`;
  }
  resetFlags() {
    this.showUnknownError = false;
    this.showProfileError = false;
    this.isUserBlocked = false;
    this.showSessionTimeoutError = false;
  }
}
