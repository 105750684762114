import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APPEND_TOKEN } from 'src/app/constants';
import { environment } from 'src/environments/environment';

export interface CarouselPartnersResponse {
  data: {
    id: number;
    attributes: {
      card: {
        id: number;
        icon: { data: { id: number; attributes: { url: string } } };
        image: { data: { id: number; attributes: { url: string } } };
        link: string;
        title: string;
        contentText: string;
      };
    };
  }[];
}

export interface HomeCarouselPartnersResponse {
  data: {
    id: number;
    attributes: {
      data: {
        id: number;
        title: string;
        contentText: string;
        link: string;
        ctaText: string;
        partnerName: string;
        icon: { data: { id: number; attributes: { url: string } } };
        image: { data: { id: number; attributes: { url: string } } };
      };
    };
  }[];
}

export interface TopPartnersResponse {
  data: {
    id: number;
    attributes: {
      data: {
        id: number;
        icon?: { data: { id: number; attributes: { url: string } } };
        image: { data: { id: number; attributes: { url: string } } };
        link: string;
        title: string;
        contentText: string;
      };
    };
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private httpClient: HttpClient) {}

  getCarouselPartners() {
    return this.httpClient.get<CarouselPartnersResponse>(
      `${environment.cmsHost}/api/partner-carousels?sort=id:ASC&populate=*,card.image,card.icon`,
      {
        context: new HttpContext().set(APPEND_TOKEN, false),
      }
    );
  }

  getTopPartners() {
    return this.httpClient.get<TopPartnersResponse>(
      `${environment.cmsHost}/api/top-partner-offers?sort=id:ASC&populate=*,data.image,data.icon`,
      {
        context: new HttpContext().set(APPEND_TOKEN, false),
      }
    );
  }

  getHomePartners() {
    return this.httpClient.get<HomeCarouselPartnersResponse>(
      `${environment.cmsHost}/api/home-page-partner-carousels?populate=*,data.image,data.icon`,
      {
        context: new HttpContext().set(APPEND_TOKEN, false),
      }
    );
  }
}
