import { Component, Input, OnInit } from '@angular/core';
import { SignInService } from 'src/app/views/sign-in/sign-in.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {
  @Input() scroll = true;
  tnc!: { id: number; content: string; title: string };

  constructor(private signInService: SignInService) {
    this.getTnCs();
  }

  ngOnInit(): void {}

  getTnCs() {
    this.signInService.getTnC().subscribe((res) => {
      this.tnc = res.data[0].attributes.tnc;
    });
  }
}
