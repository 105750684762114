import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

interface Option {
  title: string;
  selected: boolean;
  choices: Choice[];
}

interface Choice {
  title: string;
  routerLink: string;
}
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  options: Option[] = [
    {
      title: 'Our company',
      selected: false,
      choices: [
        { title: 'Homepage', routerLink: '/' },
        { title: 'Our partners', routerLink: '/partners' },
      ],
    },
    {
      title: 'Support',
      selected: false,
      choices: [
        { title: 'FAQs', routerLink: '/support' },
        { title: 'Contact us', routerLink: '/support' },
      ],
    },
  ];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  toggleInfo(option: Option) {
    option.selected = !option.selected;
  }

  handleNavigation(choice: Choice) {
    if (choice.title == 'Contact us') {
      this.router
        .navigate([choice.routerLink], {
          queryParams: { to: 'help' },
        })
        .catch(() => {});
    } else {
      this.router.navigate([choice.routerLink]).catch(() => {});
    }
  }
}
