import { Component, OnInit } from '@angular/core';
import { ToastService } from './toast.service';
@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
})
export class ToastComponent implements OnInit {
  constructor(private toastr: ToastService) {}

  toastMessage = '';
  showToast = false;
  ngOnInit(): void {
    this.toastr.status.subscribe((msg: string | null) => {
      if (msg == null) {
        this.showToast = false;
      } else {
        this.showToast = true;
        this.toastMessage = msg;
      }
    });
  }

  onClick(): void {
    this.showToast = false;
  }
}
