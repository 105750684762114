// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from './environment-interface';

export const environment: IEnvironment = {
  production: false,
  apiHost: 'https://r-one-website-qa.api.engageapps.jio',
  jcnApi: 'https://sitofferapi.coupon.jio/offerstore',
  storeLocaterURL: 'https://storelocator.ril.com/Retail.aspx',
  cmsHost: 'https://r-one-website-qa.api.engageapps.jio/cms',
  enableRoneGateway: false,
  roneGatewayAPIHost: 'https://qa-r-one-gw.jio-loyalty-network.com',
  jcnClientId: 'jionews',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
