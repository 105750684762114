import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  Input,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TooltipComponent } from './tooltip.component';

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective {
  @Input() tooltipText = '';

  private tooltipComponent?: ComponentRef<TooltipComponent>;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: MouseEvent): void {
    if (this.tooltipComponent || !this.isTextOverflowing()) {
      return;
    }

    const tooltipComponentFactory =
      this.componentFactoryResolver.resolveComponentFactory(TooltipComponent);
    this.tooltipComponent = tooltipComponentFactory.create(this.injector);

    this.appRef.attachView(this.tooltipComponent.hostView);
    this.document.body.appendChild(
      this.tooltipComponent.location.nativeElement
    );

    this.setTooltipComponentProperties(event);
    this.tooltipComponent.hostView.detectChanges();
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    if (this.tooltipComponent) {
      this.appRef.detachView(this.tooltipComponent.hostView);
      this.tooltipComponent.destroy();
      this.tooltipComponent = undefined;
    }
  }

  private setTooltipComponentProperties(event: MouseEvent): void {
    if (this.tooltipComponent) {
      this.tooltipComponent.instance.text = this.tooltipText;
      this.tooltipComponent.instance.left = event.clientX + 10;
      this.tooltipComponent.instance.top = event.clientY + 10;
    }
  }

  private isTextOverflowing(): boolean {
    const element = this.elementRef.nativeElement;
    return element.scrollWidth > element.clientWidth;
  }
}
