import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpContext } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { APPEND_TOKEN } from 'src/app/constants';

export interface PointsSummaryServerResponse {
  available: number;
  availableForDisplay: number;
  earned: number;
  redeemed: number;
  pending: number;
  expired: number;
  pointsMultiplierPoints?: number;
  rupeePerPoint?: number;
  pointsSyncedAt?: string;
  amountEarned?: number;
  amountRedeemed?: number;
  amountExpired?: number;
  availableAmount?: number;
  availableAmountForDisplay: number;
  pendingAmount?: number;
  pointsMultiplierAmount?: number;
}

interface Option {
  value: string;
  viewValue: string;
}

interface AdditionalFieldObj {
  fieldName: string;
  fieldValue: string;
}

export interface ProfileDetailsCMS {
  data: {
    id: number;
    attributes: {
      data: Option[];
    };
  }[];
}

export interface EmployersDetailsCMS {
  data: {
    id: number;
    attributes: {
      value: string;
      viewValue: string;
    };
  }[];
}

interface ChangeHistoryObj {
  fieldName: string;
  fieldValue: string;
}
export interface ProfileServerResponse {
  firstName: string;
  lastName: string;
  dob: {
    date: number;
    month: number;
    year: number;
    combined: number;
  };
  isProfileCompleted: boolean;
  additionalFields: AdditionalFieldObj[];
  createdAt: string;
  commEmailId: string;
  isCommEmailIdVerified: boolean;
  commEmailIdVerifiedAt: string;
  status: 'active' | 'redeem-blocked' | 'blocked';
  changeHistory: ChangeHistoryObj[];
  identityValue: number;
  enrolledAt?: string;
}

interface UpdateProfilePayload {
  firstName?: string;
  commEmailId?: string;
  emailVerificationUrl?: string;
  additionalFields?: {
    fieldName?: string;
    fieldValue?: string;
  }[];
}

interface VerifyEmailPayload {
  token: string;
}

interface VerifyEmailResponse {
  success: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private httpClient: HttpClient) {}

  private profile!: ProfileServerResponse;

  getPointsSummary() {
    const apiHost = environment.enableRoneGateway
      ? String(environment.roneGatewayAPIHost)
      : environment.apiHost;
    return this.httpClient.get<PointsSummaryServerResponse>(
      apiHost + '/api/user/points-summary'
    );
  }

  getProfileInfo() {
    return this.httpClient
      .get<ProfileServerResponse>(environment.apiHost + '/api/user/profile')
      .pipe(
        tap((res) => {
          this.profile = res;
        })
      );
  }

  getProfileSync() {
    return this.profile;
  }

  updateProfileInfo(form: UpdateProfilePayload) {
    return this.httpClient.put<ProfileServerResponse>(
      environment.apiHost + '/api/user/profile',
      form
    );
  }

  resendEmail(form: UpdateProfilePayload) {
    return this.httpClient.post<ProfileServerResponse>(
      environment.apiHost + '/api/user/resend-email',
      form
    );
  }

  verifyEmail(form: VerifyEmailPayload) {
    return this.httpClient.post<VerifyEmailResponse>(
      environment.apiHost + '/api/user/verify-email',
      form
    );
  }

  getEmployers(page = 1, filterBy = '') {
    return this.httpClient.get<EmployersDetailsCMS>(
      `${environment.cmsHost}/api/employers?populate=*&pagination[page]=${page}&filters[value][$containsi]=${filterBy}`,
      {
        context: new HttpContext().set(APPEND_TOKEN, false),
      }
    );
  }

  getHighestEducation() {
    return this.httpClient.get<ProfileDetailsCMS>(
      `${environment.cmsHost}/api/highest-level-of-educations?populate=*`,
      {
        context: new HttpContext().set(APPEND_TOKEN, false),
      }
    );
  }

  getMotherTongue() {
    return this.httpClient.get<ProfileDetailsCMS>(
      `${environment.cmsHost}/api/mother-tongues?populate=*`,
      {
        context: new HttpContext().set(APPEND_TOKEN, false),
      }
    );
  }

  getOccupations() {
    return this.httpClient.get<ProfileDetailsCMS>(
      `${environment.cmsHost}/api/occupations?populate=*`,
      {
        context: new HttpContext().set(APPEND_TOKEN, false),
      }
    );
  }

  getInterests() {
    return this.httpClient.get<ProfileDetailsCMS>(
      `${environment.cmsHost}/api/interests?populate=*`,
      {
        context: new HttpContext().set(APPEND_TOKEN, false),
      }
    );
  }

  getMaritalStatuses() {
    return this.httpClient.get<ProfileDetailsCMS>(
      `${environment.cmsHost}/api/marital-statuses?populate=*`,
      {
        context: new HttpContext().set(APPEND_TOKEN, false),
      }
    );
  }

  getSyncProfile(clientId: string, idamAccessToken: string) {
    return this.httpClient
      .post<ProfileServerResponse>(
        environment.apiHost + '/auth/retail-idam/sync-idam-profile',
        {
          idamAccessToken: idamAccessToken,
          clientId,
        }
      )
      .pipe(
        tap((res) => {
          this.profile = res;
        })
      );
  }
}
