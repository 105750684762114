import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APPEND_TOKEN, PLATFORM } from 'src/app/constants';
import { environment } from 'src/environments/environment';

export interface FAQs {
  data: {
    id: number;
    attributes: {
      faqs: {
        id: number;
        question: string;
        answer: string;
      }[];
    };
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class AppService {
  patform = PLATFORM.WEB_APP;

  constructor(private httpClient: HttpClient) {}

  getHomePageFAQs() {
    return this.httpClient.get<FAQs>(
      `${environment.cmsHost}/api/home-page-faqs?populate=*`,
      {
        context: new HttpContext().set(APPEND_TOKEN, false),
      }
    );
  }

  detectDeviceType(): string {
    const width = window.innerWidth;
    if (width <= 768) {
      return 'smartphone';
    } else {
      return 'desktop';
    }
  }

  setPlatform(platform: PLATFORM) {
    this.patform = platform;
  }

  getPlatform() {
    return this.patform;
  }
}
