import { Component, Input, OnInit } from '@angular/core';
import { SignInService } from 'src/app/views/sign-in/sign-in.service';

interface PolicyObj {
  id: number;
  content: string;
  title: string;
}

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent implements OnInit {
  @Input() scroll = true;
  policy!: PolicyObj;

  constructor(private signInService: SignInService) {
    this.getPolicies();
  }

  ngOnInit(): void {}

  getPolicies() {
    this.signInService.getPrivacyPolicies().subscribe((res) => {
      this.policy = res.data[0].attributes.policies;
    });
  }
}
