import { Component, OnInit } from '@angular/core';
import { NativeBridgeService } from 'src/app/core/services/native-bridge.service';
import { DashboardService } from 'src/app/views/main/dashboard/dashboard.service';
interface Slide {
  tag: string;
  title: string;
  imageURL: string;
  logoURL: string;
  link: string;
}

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
  currentSlideIndex = 0;
  isLoading = true;
  slides: Slide[] = [];
  touchEndX = 0;
  touchStartX = 0;

  constructor(
    private dashboardService: DashboardService,
    private nativeBridgeService: NativeBridgeService
  ) {
    this.getCarouselImages();
  }

  ngOnInit(): void {}

  getCarouselImages() {
    this.dashboardService.getCarouselPartners().subscribe(
      (res) => {
        res.data.forEach((d) => {
          const slide = {
            tag: d.attributes.card.title,
            title: d.attributes.card.contentText,
            link: d.attributes.card.link,
            imageURL: d.attributes.card.image.data.attributes.url,
            logoURL: d.attributes.card.icon.data.attributes.url,
          };
          this.slides.push(slide);
          this.isLoading = false;
        });
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  goToOffer() {
    const url = this.slides[this.currentSlideIndex].link;
    window.open(url);
    this.nativeBridgeService.launchBrowser(url);
  }

  handleSwipe() {
    const distance = this.touchEndX - this.touchStartX;
    let index = 0;
    if (distance > 0) {
      // Swipe right
      index = this.currentSlideIndex - 1;
    } else if (distance < 0) {
      // Swipe left
      index = this.currentSlideIndex + 1;
    }
    this.handleSlide(index);
  }

  handleArrowClick(towardsDirection: 'right' | 'left') {
    let index = 0;
    if (towardsDirection === 'right') {
      index = this.currentSlideIndex + 1;
    } else {
      index = this.currentSlideIndex - 1;
    }
    this.handleSlide(index);
  }

  handleSlide(index: number) {
    if (index < 0) {
      index = this.slides.length - 1;
    }
    if (index >= this.slides.length) {
      index = 0;
    }

    this.currentSlideIndex = index;
  }
  moveToSlide(index: number) {
    this.currentSlideIndex = index;
  }

  handleTouchstart(event: TouchEvent) {
    this.touchStartX = event.touches[0].clientX;
  }

  handleTouchMove(event: TouchEvent) {
    this.touchEndX = event.touches[0].clientX;
  }

  handleTouchEnd() {
    if (this.touchStartX !== 0 && this.touchEndX !== 0) {
      this.handleSwipe();
      this.touchStartX = 0;
      this.touchEndX = 0;
    }
  }
}
