import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

export const signInProtectionGuard: CanActivateFn = () => {
  const router = inject(Router);

  if (!environment.clientIdForRetailIDAM && !environment.retailIDAMURL) {
    return true;
  } else {
    router.navigate(['/']).catch(() => {});
    return false;
  }
};
