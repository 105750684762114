<div id="code-input">
    <input #inputEl *ngFor="let input of inputs.controls;let i=index" type="tel" maxlength="1"
        [formControl]="$any(input)" (keypress)="handleKeyPress($event,i)" (paste)="handlePaste($event, i)"
        (focus)="handlePlaceholder($event)" (blur)="handlePlaceholder($event)" (input)="checkOTPInputsFilled($event, i)"
        placeholder="0" (keydown)="handleKeyDown($event, i)" [attr.autocomplete]="i === 0 ? 'one-time-code' : null"
        inputmode="numeric" type="text">
</div>

<div *ngIf="showOTPError" class="error-text">
    Incorrect code. Try again or generate a new OTP.
</div>

<div class="error-text" *ngIf="showOTPExhaustedError">
    You have exhausted 2 attempts for OTP validation. Please try again after sometime.
</div>

<div class="resend">
    <div class="link__resend-otp otp-disabled text-otp" *ngIf="secondsLeft !== 0">
        Resend OTP in
        00:{{secondsLeft| number:'2.0-0'}}
    </div>
    <div class="link__resend-otp text-otp" *ngIf="secondsLeft === 0" (click)="resendOTP()">
        Resend OTP
    </div>
    <div  *ngIf="!otpExpired && validTimer" >
        <span class="link__expiration-otp">Valid for </span> <span class="expired__timer">{{ minutesLeft | number: '2.0-0' }}:{{ secondsLeftForExpiration | number: '2.0-0' }}</span>
    </div>
    <div class="link__resend-otp text-otp" *ngIf="otpExpired">
        OTP has expired
    </div>  
      
     
</div>

  
