<div class="outer-container" *ngIf="slides.length && !isLoading">
    <div id="carousel">
        <div (touchstart)="handleTouchstart($event)" (touchmove)="handleTouchMove($event)"
            (touchend)="handleTouchEnd()" class="container">
            <div class="slide" *ngFor="let slide of slides; let i = index"
                [ngClass]="{'slide-active' : i ===   currentSlideIndex}">
                <!-- Slide  content -->
                <section class="banner" (click)="goToOffer()">
                    <div class="banner__left-section">
                        <div>
                            <h2 class="banner__left-section-heading">{{slide.tag}}</h2>
                        </div>
                        <div class="banner__left-section-paragraph-container">
                            <p class="banner__left-section-paragraph">{{slide.title}}</p>
                        </div>
                    </div>
                    <div class="banner__right-section">
                        <img class="banner__right-section-image" [src]="slide.imageURL">
                        <div class="banner__right-section-logo">
                            <img class="banner__right-section-logo-image" [src]="slide.logoURL">
                        </div>
                    </div>
                </section>
            </div>

            <!-- Previous / Next buttons -->
            <div class="carousel-buttons">
                <button id="prev" (click)="handleArrowClick('left')">
                    <img src="assets/images/carousel/previous-arrow.svg" alt="Previous">
                </button>
                <button id="next" (click)="handleArrowClick('right')">
                    <img src="assets/images/carousel/next-arrow.svg" alt="Next">
                </button>
            </div>
        </div>
    </div>

    <!-- Dots -->
    <div class="dots">
        <span class="dot" *ngFor="let slide of slides; let i = index"
            [ngClass]="{'dot-active':i ===   currentSlideIndex}" (click)="moveToSlide(i)"></span>
    </div>
</div>

<!-- skeleton for carousel -->

<div class="outer-container" *ngIf="isLoading">
    <div id="carousel">
        <div class="container">
            <div class="slide slide-active" >
                <section class="banner">
                    <div class="banner__left-section-skeleton">
                        <div class="banner-header"></div>
                        <div class="skeleton-paragraph">
                            <div class="skeleton-desc"></div>
                            <div class="skeleton-description1"></div>
                            <div class="skeleton-description2"></div>
                        </div>
                    </div>
                    <div class="banner__right-section banner__right-section-skeleton">
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>