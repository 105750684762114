@if(showUnknownError) {
<div class="custom-modal modal" (click)="handlePopup()">
    <div class="modal-content custom-height" (click)="handleClickInsidePopup($event)">
        <div class="popup-heading">Oops! Something went wrong</div>
        <div class="popup-content">Please wait a few seconds before trying again.</div>
        <div class="button" (click)="handlePopup()">ok</div>
    </div>
</div>
}
@else if (isUserBlocked){
    <div class="custom-modal modal" (click)="handlePopup()">
    <div class="modal-content custom-height" (click)="handleClickInsidePopup($event)">
        <div class="popup-heading">Hold on a sec !</div>
        <div class="popup-content">We noticed your account status might prevent changing your mobile number.</div>
        <div class="button" (click)=" checkFAQ()">Go to Support</div>
    </div>
</div>
}

@else if(showProfileError) {
<div class="custom-modal modal" (click)="handlePopup()">
    <div class="modal-content custom-height" (click)="$event.stopPropagation()">
        <img src="assets/images/common/close-black.svg" alt="Close" class="close" (click)="onCloseClick()">
        <div class="popup-heading">{{heading}}</div>
        <div class="popup-content">{{description}}</div>
        <div class="button" (click)="contactSupport()">Contact Support</div>
        <div class="faq" (click)="checkFAQ()">Check FAQ</div>
    </div>
</div>
}

@else if(showSessionTimeoutError) {
    <div class="custom-modal modal" (click)="handlePopup()">
        <div class="modal-content custom-height" (click)="$event.stopPropagation()">
            <img src="assets/images/common/close-black.svg" alt="Close" class="close" (click)="onCloseClick()">
            <div class="popup-heading">{{heading}}</div>
            <div class="popup-content">{{description}}</div>
            <div class="button" (click)="onCloseClick()">OK</div>
        </div>
    </div>
}