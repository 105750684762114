import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  status: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
    null
  );

  timer: number | undefined;
  constructor() {}

  showToast(msg: string, close = true) {
    this.status.next(msg);
    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (close) {
      this.timer = window.setTimeout(() => {
        this.status.next(null);
      }, 5000);
    }
  }
}
