<section class="container" *ngIf="showToast">
<div class="toast__container">
<div class="toast__content">
    <div class="toast__text">{{toastMessage}}</div>
    <div class="close__button" (click)="onClick()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.93926 7.99993L3 4.06077L4.06066 3.00011L7.99992 6.93927L11.9392 3L12.9999 4.06066L9.06058 7.99993L13 11.9393L11.9394 13L7.99992 9.0606L4.06069 13L3.00003 11.9393L6.93926 7.99993Z" fill="#003380"/>
          </svg>
    </div>
</div>
</div>
</section>