/* eslint-disable */

import { Injectable, NgZone } from '@angular/core';

declare const window: any;
declare const webkit: any;

@Injectable({
  providedIn: 'root',
})
export class NativeBridgeService {
  constructor(private zone: NgZone) {
    this.setupNativeListener();
    this.setupCallbacksFromNative();
  }

  // Call native function from Angular
  callNativeFunction(data: any) {
    if (window['nativeInterface']){
      window['nativeInterface'].postMessage(JSON.stringify(data));
    } else {
      console.warn('Native interface not available');
    }
  }

  // Set up listener for messages from native code
  private setupNativeListener() {
    window['onNativeMessage'] = (data: any) => {
      const parsedData = JSON.parse(data);
      console.log('Received message from native:', parsedData);
      // Handle the message from native code
    };
  }

  public setupCallbacksFromNative(): void {
    window.sendJwt = () => {
      this.zone.run(() => {
        // this.authService.setAuthInfo({ jwt});
        // this.router.navigate(['/']);
      });
    };

    window.handleBackKey = () => {
      this.zone.run(() => {});
    };
  }

  sendAuthToken(token: string): void {
    this.externalCall(
      btoa(
        JSON.stringify({
          type: 'authToken',
          value: token,
        })
      )
    );
  }

  launchBrowser(url: string): void {
    this.externalCall(
      btoa(
        JSON.stringify({
          type: 'launchbrowser',
          value: url,
        })
      )
    );
  }

  tokenExpired(): void {
    this.externalCall(
      btoa(
        JSON.stringify({
          type: 'authTokenExpired',
        })
      )
    );
  }

  logout(): void {
    this.externalCall(
      btoa(
        JSON.stringify({
          type: 'logout',
        })
      )
    );
  }

  redirectToDashboard(): void {
    this.externalCall(
      btoa(
        JSON.stringify({
          type: 'dashboardRedirect',
        })
      )
    );
  }

  private externalCall(data: string): void {
    try {
      if (window.android && window.android.__externalCall) {
        window.android.__externalCall(data);
      }
      if (window.__externalCall) {
        window.__externalCall(data);
      }
      if(window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(data);
      }
      webkit.messageHandlers.callback.postMessage(data);
    } catch (e) {
      console.log('external call failed');
    }
  }
}
