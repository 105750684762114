<footer class="footer">

    <!-- Top section -->
    <div class="footer__top">
        <div class="accordion" [ngClass]="{'accordion--open' : option.selected }" *ngFor="let option of options"
            (click)="toggleInfo(option)">
            <div class="accordion__title">
                <h3 class="accordion__heading jiotype">{{option.title}}</h3>
                <img src="assets/images/common/chevron-down.svg" alt="Expand" class="accordion__icon">
            </div>
            <div class="accordion__content">
                <ul class="accordion__list" *ngFor="let choice of option.choices">
                    <li class="accordion__item"><a (click)="handleNavigation(choice)"
                            class="accordion__link">{{choice.title}}</a></li>
                </ul>
            </div>
        </div>
    </div>

    <div class="footer__divider"></div>

    <!-- Base section -->
    <div class="footer__base">
        <div footer__logo-and-copyright>
            <div class="footer__logo">
                <img src="assets/images/footer/logo-red.png" alt="Reliance Industries Limited">
            </div>
            <div class="footer__copyright">
                <p>Copyright © 2023 Reliance Industries Limited. All rights reserved.</p>
            </div>
        </div>
        <div class="footer__links">
            <a href="/" class="footer__link" [routerLink]="['/privacy-policy']"> Privacy policy</a>
            <a href="/" class="footer__link" [routerLink]="['/terms-conditions']">Terms &amp; Conditions</a>
        </div>
    </div>

</footer>