import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { timer, Subject } from 'rxjs';
import { take, takeWhile, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();
  constructor() {}

  size = 6;
  inputs = this.getFormArray(this.size);
  @ViewChildren('inputEl') inputEls!: QueryList<ElementRef<HTMLInputElement>>;
  sheduledFocusIndex = -1;
  otpValue = '';
  otpReRequest = 30;
  secondsLeft = 0;
  showOTPError = false;
  showOTPExhaustedError = false;
  validTimer = true;
  minutesLeft = 15;
  secondsLeftForExpiration = 0;
  otpExpired = false;
  @Output() submitMobileNumber = new EventEmitter<string>();

  ngOnInit(): void {
    this.startTimerForExpiration();
  }

  getFormArray(size: number): FormArray {
    const arr = [];

    for (let i = 0; i < size; i++) {
      arr.push(new FormControl('', [Validators.required]));
    }

    return new FormArray(arr);
  }

  focusInput(idx: number) {
    setTimeout(() => this.inputEls.get(idx)?.nativeElement.focus());
  }

  startTimer() {
    const countdown$ = timer(0, 1000).pipe(take(this.otpReRequest + 1));

    countdown$.subscribe((secondsLeft) => {
      this.secondsLeft = this.otpReRequest - secondsLeft;
    });
  }

  startTimerForExpiration() {
    const fifteenMinutesInSeconds = 15 * 60;

    this.destroy$.next();
    timer(0, 1000)
      .pipe(
        takeUntil(this.destroy$),
        takeWhile((secondsElapsed) => secondsElapsed <= fifteenMinutesInSeconds)
      )
      .subscribe((secondsElapsed) => {
        const secondsLeft = fifteenMinutesInSeconds - secondsElapsed;
        this.minutesLeft = Math.floor(secondsLeft / 60);
        this.secondsLeftForExpiration = secondsLeft % 60;

        if (secondsLeft === 0) {
          this.otpExpired = true;
        }
      });
  }

  resendOTP() {
    this.destroy$.next();
    this.minutesLeft = 15;
    this.secondsLeftForExpiration = 0;
    this.otpExpired = false;
    this.submitMobileNumber.next('');
  }
  hideErrorMessage() {
    this.showOTPError = false;
    this.showOTPExhaustedError = false;
  }

  handleKeyPress(event: KeyboardEvent, index: number) {
    let pos = index;

    if (event.key === 'v' && event.metaKey) {
      return true;
    }

    if (this.inputs.controls[index].value && event.key !== 'Enter') {
      this.inputs.controls[index].setValue('');
    }
    if (event.key === 'Backspace') {
      pos = index - 1;
    } else if (/[0-9]/.test(event.key)) {
      pos = index + 1;
    } else {
      event.preventDefault();
    }

    this.sheduledFocusIndex = pos;

    return /[0-9]/.test(event.key);
  }

  handleKeyDown(e: KeyboardEvent, index: number) {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      if (index > 0) {
        this.sheduledFocusIndex = index - 1;
      }
    }
  }

  handlePlaceholder(event: FocusEvent) {
    const element = event.target as HTMLInputElement;
    if (event.type === 'focus') {
      element.placeholder = '';
    } else {
      if (element.value === '') {
        if (element.placeholder === '') {
          element.placeholder = '0';
        }
      }
    }
    this.hideErrorMessage();
  }

  checkOTPInputsFilled(event: Event, index: number) {
    const codeInputs: NodeListOf<HTMLInputElement> =
      document.querySelectorAll('#code-input input');

    let otpString = '';
    Array.from(codeInputs).forEach((input) => {
      otpString = otpString + input.value;
    });
    this.otpValue = otpString;

    const inputElement = event.target as HTMLInputElement;

    if (inputElement.value.length == this.size && index == 0) {
      this.handleAutoFill(inputElement.value);
    } else if (this.sheduledFocusIndex != -1) {
      this.focusInput(this.sheduledFocusIndex);
      this.sheduledFocusIndex = -1;
    }
  }

  handlePaste(e: ClipboardEvent, idx: number) {
    e.preventDefault();
    if (idx !== 0) {
      // If the target input is not the first one - ignore
      return;
    }
    const pasteData = e.clipboardData?.getData('text');
    this.handleAutoFill(pasteData);
  }

  handleAutoFill(data: string | undefined) {
    const regex = new RegExp('\\d{' + String(this.size) + '}');

    if (!data || !regex.test(data)) {
      // If there is nothing to be pasted or the pasted data does not
      // comply with the required format - ignore the event
      return;
    }

    for (let i = 0; i < data.length; i++) {
      this.inputs.controls[i].setValue(data[i]);
    }

    setTimeout(() => {
      this.inputEls.get(this.inputEls.length - 1)?.nativeElement.focus();
    }, 0);
  }
}
